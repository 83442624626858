import { configureStore } from '@reduxjs/toolkit';
import storage from 'redux-persist/lib/storage'
import {
  persistReducer, persistStore, FLUSH,
  REHYDRATE,
  PAUSE,
  PERSIST,
  PURGE,
  REGISTER,
} from 'redux-persist';

// Define action types as constants
const SET = 'set';
const SET_USER_DATA = 'setUserData';
const LOGOUT = 'logout';

const initialState = {
  sidebarShow: true,
  userData: null,
}

// Action creators
export const setUserData = (userData) => ({
  type: SET_USER_DATA,
  userData,
});


const rootReducer = (state = initialState, { type, ...rest }) => {
  switch (type) {
    case SET:
      return { ...state, ...rest };
    case SET_USER_DATA:
      return { ...state, userData: rest.userData };
    case LOGOUT:
      return { ...initialState, sidebarShow: state.sidebarShow };
    default:
      return state;
  }
};

const persistConfig = {
  key: 'root',
  storage,
  transforms: [],
};

const persistedReducer = persistReducer(persistConfig, rootReducer);

const store = configureStore({
  reducer: persistedReducer,
  middleware: (getDefaultMiddleware) =>
    getDefaultMiddleware({
      serializableCheck: {
        ignoredActions: [FLUSH, REHYDRATE, PAUSE, PERSIST, PURGE, REGISTER],
      },
    }),
});

const persistor = persistStore(store);

export { persistor, store };
