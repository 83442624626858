import React, { Suspense } from 'react';
import { HashRouter, Route, Routes } from 'react-router-dom';
import '../scss/style.scss';


// Containers
const DefaultLayout = React.lazy(() => import('../components/layout/DefaultLayout'));

// Pages
const Login = React.lazy(() => import('../components/views/authentication/login/Login'));
const Register = React.lazy(() => import('../components/views/authentication/register/Register'));
const LandingScreen = React.lazy(() => import('../components/views/LandingScreen'));

const loading = (
  <div className="pt-3 text-center">
    <div className="sk-spinner sk-spinner-pulse"></div>
  </div>
);

const App = () => {
  
  return (
    <HashRouter>
      <Suspense fallback={loading}>
        <Routes>
          <Route exact path="/" name="Landing Page" element={<LandingScreen />} />
          <Route exact path="/welcome" name="Landing Page" element={<LandingScreen />} />
          <Route exact path="/login" name="Login Page" element={<Login />} />
          <Route exact path="/register" name="Register Page" element={<Register />} />
          <Route path="*" name={"Home"} element={<DefaultLayout />} />
        </Routes>
      </Suspense>
    </HashRouter>
  );
};

export default App;
